<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="userSupportNotificationsCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          v-if="userSupportNotificationsCount > 0"
          pill
          variant="light-primary"
        >
          {{ userSupportNotificationsCount }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          Support Notifications
        </h6>
      </div>

      <p v-if="!userSupportNotificationsCount" class="media d-flex align-items-center">
          😕 No support notifications available</p>

      <!-- Account Notification -->
      <div v-if="userSupportNotificationsCount > 0">
        
        <b-dropdown-item
          v-for="supportNotification in unreadSupportNotifications"
          :key="supportNotification._id"
          @click="onSupportNotificationItemClicked(supportNotification)"
        >
          <b-media class="bg-transparent">
            <template #aside>
              <b-avatar
                v-b-tooltip.hover.right="getValueFromSource(supportNotification, `comments[${getValueFromSource(supportNotification, 'comments', []).length}].sender.full_name`)"
                size="32"
                :src="getValueFromSource(supportNotification, `comments[${getValueFromSource(supportNotification, 'comments', []).length - 1}].sender.avatar.path`)"
                :text="avatarText(getValueFromSource(supportNotification, `comments[${getValueFromSource(supportNotification, 'comments', []).length - 1}].sender.full_name`))"
                variant="light-success"
              />
            </template>

            <div class="d-flex align-items-center justify-content-between">
              <div class="" style="flex-basis: 90%">
                <p class="media-heading text-ellipsis">
                  <span class="font-weight-bolder inline-block w-100">
                    {{ supportNotification.subject }}
                  </span>
                </p>

                <p class="notification-text inline-block text-ellipsis">
                  {{ getValueFromSource(supportNotification, `comments[${getValueFromSource(supportNotification, 'comments', []).length - 1}].message`) }}</p>
              </div>

              <b-badge
                v-if="getValueFromSource(supportNotification, 'comments', []).length > 0"
                pill
                variant="success"
              >
                {{ 
                  getValueFromSource(supportNotification, 'comments', [])
                  .filter((comment) => (comment.viewers || []).length === 0).length 
                }}
              </b-badge>
            </div>
          </b-media>
        </b-dropdown-item>
      </div>
    
      <!-- System Notification Toggler -->
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
      </div>

        <p v-if="!userSystemNotificationsCount" class="media d-flex align-items-center">
          😕 No system notifications available</p>

      <!-- System Notifications -->
      <b-link
        v-for="notification in userSystemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <!-- <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >Read all notifications</b-button>
    </li> -->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox, VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

import { get } from "lodash";

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  computed: {
    userSupportNotifications(){
      return this.$store.getters[`auth/userSupportNotifications`]
    },
    unreadSupportNotifications(){
      return this.userSupportNotifications.filter((notification) => (notification.comments || []).some((comment) => (comment.viewers || []).length === 0))
    },
    userSupportNotificationsCount(){
      return this.userSupportNotifications.reduce(
        (accumulator, cIssue) => accumulator +
          get(cIssue, "comments", []).filter(
            (comment) => (comment.viewers || []).length === 0
          ).length,
        0
      );
    },
    userSystemNotifications(){
      return this.$store.getters[`auth/userSystemNotifications`];
    },
    userSystemNotificationsCount(){
      return this.$store.getters[`auth/userSystemNotifications`].length;
    }
  },
  watch: {
    userSupportNotificationsCount: {
      handler(newMessagesCount){
        if (newMessagesCount > 0){
          this.playSound("door_bell")
        }
      }
    }
  },
  methods: {
    onSupportNotificationItemClicked(){
      // const routeName = this.isCurrentUserAdmin ? 'admin-single-support-ticket' : 'auth-support-tickets';
      // const routePropType = this.isCurrentUserAdmin ? 'params' : 'query';

      // this.$router.replace({ 
      //   name: routeName, 
      //   [routePropType]: { 
      //     ticket_id: supportNotificationData._id 
      //   } 
      // });
    }
  }
}
</script>

<style scoped>
  .text-ellipsis {
    width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
