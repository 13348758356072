export default [
  {
    title: 'Home',
    action: "read",
    route: "app-root",
    resource: "AppRoot"
  },
  {
    title: 'About Us',
    action: "read",
    route: "about-us",
    resource: "PublicPage"
  },
  {
    title: 'Jobs',
    action: "read",
    route: "public-jobs",
    resource: "PublicPage"
  },
  {
    title: 'Job Seekers',
    action: "read",
    route: "seekers",
    resource: "PublicPage"
  },
  {
    title: 'Job Providers',
    action: "read",
    route: "providers",
    resource: "PublicPage"
  },
  {
    title: 'Resources',
    action: "read",
    route: "resources",
    resource: "PublicPage"
  },
]
