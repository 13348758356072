var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",class:[
  {
    'show-overlay': _vm.$store.state.app.shallShowOverlay 
  }, 
  {
    'app-content': true
  },
  _vm.$route.meta.contentClass]},[_c('div',{staticClass:"content-overlay"}),_c('div',{staticClass:"header-navbar-shadow"}),_c('div',{staticClass:"content-wrapper",class:{
      'container': _vm.contentWidth === 'boxed',
      'p-0': _vm.contentWidth === 'boxed',
      'override-content-wrapper': false,
      'explicit-height': _vm.$route.meta.useContentWrapper
    }},[_c('div',{staticClass:"content-body"},[_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_vm._t("default")],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }