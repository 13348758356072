<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Navbar -->
    <div v-if="showNavMenu" class="custom-nav-bar">
      <b-navbar
        :toggleable="false"
        :variant="navbarBackgroundColor"
        class="header-navbar navbar navbar-shadow flex-column"
        :class="[navbarTypeClass]"
      >
        <slot
          name="navbar"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :navbarBackgroundColor="navbarBackgroundColor"
          :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
        >
          <app-navbar-vertical-layout class="w-100" :toggle-vertical-menu-active="toggleVerticalMenuActive" />
        </slot>
      </b-navbar>
    </div>
    
    <!--/ Navbar -->

    <vertical-nav-menu
      v-if="!isNavMenuHidden && showNavMenu"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot
          name="vertical-menu-header"
          v-bind="slotProps"
        />
      </template>
    </vertical-nav-menu>

    <a href="#" 
      :class="['nav-bar-toggler', !verticalMenuCollapsedState ? 'expanded' : 'collapsed']" 
      class="d-none d-lg-flex"
      @click="verticalMenuCollapsedState = !verticalMenuCollapsedState"
      >
      <feather-icon :icon="!verticalMenuCollapsedState ? 'ChevronLeftIcon' : 'ChevronRightIcon'" size="30" />
    </a>

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- /Footer -->

    <slot name="customizer" />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import router from '@router'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar, BLink } from 'bootstrap-vue'

import { useUtils as useAclUtils } from '@core/libs/acl'

import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import AnnoucementDisplay from "@core/components/shared/announcement/AnnouncementDisplay.vue"

import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'

export default {
  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    BLink,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
    AnnoucementDisplay
  },
  mixins: [mixinVerticalLayout],
  computed: {
    settings(){
      return this.$store.getters[`auth/settings`];
    },
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
    isPhoneVerified(){
      return this.$store.getters[`auth/isPhoneVerified`]
    },
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    isBoarded(){
      const userRole = this.getValueFromSource(this.userData, 'user_type', '');
      return userRole === "client" ? this.getValueFromSource(this.userData, 'has_completed_onboarding', false) : true;
    },
    showNavMenu(){
      const requirePhoneVerification = this.getValueFromSource(this.settings, 'security_settings.require_client_phone_verification', false);

      const conditions = [this.isLoggedIn, this.isBoarded];
      if (requirePhoneVerification){
        conditions.push(this.isPhoneVerified)
      }

      return conditions.every(condition => condition);
    }
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
      isVerticalMenuCollapsed: verticalMenuCollapsedState
    } = useAppConfig()

    const { canViewVerticalNavMenuLink } = useAclUtils()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    const isNavLinkActive = routeName => {
      // Matched routes array of current route
      const matchedRoutes = router.currentRoute.matched
      return matchedRoutes.some(route => route.name === routeName || route.meta.navActiveLink === routeName)
    }

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

   const preprocessAdminMenuItems = (items) => {
      const processedItems = [];
      items.forEach((item) => {
        if (items.header) {
          return
        }
        if (item.children) {
          processedItems.push(...item.children);
        } else {
          processedItems.push(item);
        }
      });
      return processedItems;
    }

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      canViewVerticalNavMenuLink,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,

      isNavLinkActive,
      preprocessAdminMenuItems,

      verticalMenuCollapsedState
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";

.custom-horizontal-menu {
  position: fixed;
  right: 0;
  right: 0;
  width: calc(100vw - (100vw - 100%) - calc(2rem * 2) - 260px);
  z-index: 11;
  background-color: $primary;
  margin: 5.8rem 2rem 0;
  border-radius: 0.428rem;
  // box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  height: 60px;
  padding: 0 !important;
}
.custom-horizontal-menu.override-custom-menu-items {
  height: auto !important;
}
.custom-menu-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
}
.override-custom-menu-items .custom-menu-items {
  justify-content: flex-start !important;
}
.custom-nav-item {
  display: flex;
  align-items: center;
  width: 100px;
  justify-content: center;
}
.custom-nav-item.active {
  background-color: $exttra-secondary;
}
</style>
