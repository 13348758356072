<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
    { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
    skin === 'dark' ? 'menu-dark' : 'menu-light'
  ]"
>
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
          name="header"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :toggleCollapsed="toggleCollapsed"
          :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item" :class="!isVerticalMenuCollapsed ? 'nav-logo-full' : 'nav-logo-small'">
            <b-link
                class="navbar-brand"
                to="/"
            >
              <span class="brand-logo">
                <b-img
                    :src="appMobileModeLogoImage"
                    alt="logo"
                    :width="!isVerticalMenuCollapsed ? '80px' : '50px'"
                />
              </span>
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >

      <!-- Navigation items-->
      <div>
        <vertical-nav-menu-items
          :items="getMenuItems"
          class="navigation navigation-main"
        />

        <div v-if="!isLoggedIn" class="signup-buttons px-2">
          <b-link class="btn btn-primary d-block mb-2" :to="{ name: 'auth-login' }">Sign In</b-link>
          <b-link class="btn btn-primary d-block" :to="{ name: 'auth-register' }">Sign up</b-link>
        </div>
      </div>

    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
// import navMenuItems from '@/navigation/vertical';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import { $themeConfig } from '@themeConfig';
import { BLink, BImg } from 'bootstrap-vue';
import { provide, computed, ref } from '@vue/composition-api';

import PublicMenuItems from "@/navigation/public";
import ClientMenuItems from "@/navigation/client";
import ProviderMenuItems from "@/navigation/provider";

import useAppConfig from '@core/app-config/useAppConfig';

import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
import useVerticalNavMenu from './useVerticalNavMenu';

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    getMenuItems(){
      if (this.isLoggedIn && this.isCurrentUserClient){
        return ClientMenuItems
      }
      
      if (this.isLoggedIn && this.isCurrentUserJobProvider){
        return ProviderMenuItems
      }

      return PublicMenuItems
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      toggleCollapsed,
      updateMouseHovered,
      collapseTogglerIcon,
      isVerticalMenuCollapsed,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage, appMobileModeLogoImage } = $themeConfig.app

    return {
      ClientMenuItems,
      PublicMenuItems,
      ProviderMenuItems,

      isMouseHovered,
      toggleCollapsed,
      updateMouseHovered,
      collapseTogglerIcon,
      isVerticalMenuCollapsed,
      perfectScrollbarSettings,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appMobileModeLogoImage
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
