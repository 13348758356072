export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: "client-home",
    action: "read",
    resource: "ClientHomePage"
  },
  {
    title: 'Jobs',
    icon: 'BriefcaseIcon',
    tagVariant: 'light-warning',
    route: "client-jobs",
    action: "read",
    resource: "ClientHomePage"
  },
  {
    title: 'Track Application',
    icon: 'BookIcon',
    // tag: '2',
    tagVariant: 'light-warning',
    route: "track-app",
    action: "read",
    resource: "ClientRoute"
  },
  {
    title: 'Manage CV',
    icon: 'BookIcon',
    // tag: '2',
    tagVariant: 'light-warning',
    route: "manage-cv",
    action: "read",
    resource: "ClientRoute"
  },
  {
    title: 'Resources',
    icon: 'CloudSnowIcon',
    tagVariant: 'light-warning',
    route: "client-blogs",
    action: "read",
    resource: "ClientRoute"
  },
  {
    title: 'Contact Us',
    icon: 'CloudSnowIcon',
    tagVariant: 'light-warning',
    route: "client-contact-us",
    action: "read",
    resource: "ClientRoute"
  },
]
