export default [
  // {
  //   title: 'Home',
  //   action: "read",
  //   icon: 'HomeIcon',
  //   route: "provider-home",
  //   resource: "ProviderHomePage"
  // },
  {
    title: 'My Jobs',
    action: "read",
    icon: 'BriefcaseIcon',
    route: "provider-home",
    resource: "ProviderHomePage"
  },
  {
    title: 'Candidates',
    action: "read",
    icon: 'UsersIcon',
    route: "candidates",
    resource: "ProviderHomePage"
  },
  {
    title: 'My Candidates',
    action: "read",
    icon: 'UserCheckIcon',
    route: "my-candidates",
    resource: "ProviderHomePage"
  },
  {
    title: 'Application Letters',
    action: "read",
    icon: 'FileTextIcon',
    route: "application-letters",
    resource: "ProviderHomePage"
  },
  {
    title: 'Scheduled Interviews',
    action: "read",
    icon: 'CalendarIcon',
    route: "scheduled-interviews",
    resource: "ProviderHomePage"
  },
  {
    title: 'Shortlisted',
    action: "read",
    icon: 'TriangleIcon',
    route: "shortlisted-candidates",
    resource: "ProviderHomePage"
  },
  {
    title: 'Company Profile',
    action: "read",
    icon: 'BriefcaseIcon',
    route: "company-profile",
    resource: "ProviderHomePage"
  },
]
