<template>
  <section>
    <div class="d-flex justify-content-center annoucment-display">
      <b-img
        v-if="getValueFromSource(announcementData, 'announcement_type') === 'image'"
        class="img-fluid"
        alt=""
        :src="getValueFromSource(announcementData, 'image_data.path', 'http://placehold.it/1050x50')"
        fluid-grow
      />

      <p v-if="getValueFromSource(announcementData, 'announcement_type') === 'text'" class="p-1 announcement-text">
        {{ getValueFromSource(announcementData, 'announcement_text') }}
      </p>
    </div>
  </section>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import { get } from "lodash"

export default {
  components: {
    BImg,
  },
  data() {
    return {}
  },
  computed: {
    announcementData(){
      return get(this.$store.getters[`auth/settings`], 'announcement', {});
    },
  }
}
</script>

<style scoped lang="scss">
  .annoucment-display {
    height: 50px;
    background-color: #459042 !important;
    @media screen and (max-width: 992px) {
      height: auto;
    }
  }
  .announcement-text {
    color: white;
    font-size: 16px;
  }
</style>
